import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Tooltip from '@mui/material/Tooltip';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import { useMediaQuery, ClickAwayListener } from '@mui/material';
import checkIcon from "../assets/check.png";
import closeIcon from "../assets/close.png";
import plusIcon from "../assets/plus.png";
import bankTransferIcon from '../assets/Zahlungsmethoden/bank-transfer.svg';
import cryptoIcon from '../assets/Zahlungsmethoden/crypto.svg';
import mastercardIcon from '../assets/Zahlungsmethoden/master-card.png';
import mifinityIcon from '../assets/Zahlungsmethoden/mifinity.svg';
import neosurfIcon from '../assets/Zahlungsmethoden/neosurf.svg';
import netellerIcon from '../assets/Zahlungsmethoden/neteller.svg';
import paysafeIcon from '../assets/Zahlungsmethoden/paysafecard.svg';
import skrillIcon from '../assets/Zahlungsmethoden/skrill.svg';
import visaIcon from '../assets/Zahlungsmethoden/visa.png';

const iconMap = {
  "Bank Überweisung": bankTransferIcon,
  "Crypto": cryptoIcon,
  "Master Card": mastercardIcon,
  "MiFINITY": mifinityIcon,
  "Neosurf": neosurfIcon,
  "Neteller": netellerIcon,
  "Paysafe": paysafeIcon,
  "Skrill": skrillIcon,
  "Visa Card": visaIcon
};

const Container = styled.div`
  background-color: #e9ecef;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
  text-align: left;
  position: relative;
  width: 80%;
  border: 1px solid #000;
`;

const NewTag = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  background-color: #3498db;
  color: white;
  padding: 5px ${props => (props.isDama ? '5px' : '40px')};
  font-weight: bold;
  transform: rotate(-45deg);
  transform-origin: top left;
  z-index: 1;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Brand = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const rotate = keyframes`
  0%, 100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%);
  }
  25% {
    clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%, 0% 0%);
  }
  75% {
    clip-path: polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%, 0% 0%);
  }
`;

const Tag = styled.span`
  color: red;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: transparent;
  position: relative;
  z-index: 1;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 5px;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #08f, #f03) border-box;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: ${rotate} 4s linear infinite;
  }
`;

const Logo = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  background-color: #000;
  box-shadow: 0 14px 18px rgba(0, 0, 0, 0.5);
`;

const CardColGroup = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-evenly;
  margin: 0 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Stat = styled.div`
  text-align: center;
  margin: 0 10px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
    white-space: nowrap;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const SubtitleLarge = styled.p`
  font-size: 2em;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Small = styled.small`
  color: #7f8c8d;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const Actions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

const TermsLink = styled.small`
  color: #7f8c8d;
  margin-bottom: 10px;
`;

const Button = styled.a`
  background-color: #041a11;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-bottom: 10px;

  &:hover {
    background-color: #b5552c;
  }
`;

const MoreInfoButton = styled.button`
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  margin-top: 5px;

  img {
    margin-left: 5px;
    width: 15px;
    height: 15px;
  }
`;

const ExtraInfoContainer = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  margin-top: 20px;
  justify-content: space-between;
`;

const InfoList = styled.div`
  flex: 1;
  margin-right: 20px;
  padding-left: 110px;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

const PaymentTypeIcons = styled.div`
  flex: 1;

  .subtitle {
    margin-bottom: 10px;
  }

  .icon-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

const BankingOption = styled.span`
  display: inline-block;
  margin: 5px;

  img {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 768px) {
    img {
      width: 50px;
      height: 50px;
    }
  }
`;

const CheckIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

const CardColGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CustomTooltip = ({ title, text, children, open, onClick }) => (
  <Tooltip
    title={
      <React.Fragment>
        <div style={{ fontWeight: 'bold', borderBottom: '1px solid #ddd', paddingBottom: '5px' }}>{title}</div>
        <div style={{ paddingTop: '5px' }}>{text}</div>
      </React.Fragment>
    }
    placement="bottom"
    open={open}
    onClick={onClick}
  >
    {children}
  </Tooltip>
);

const BonusEntry = ({
  logo,
  title,
  tag,
  stats,
  link,
  extraInfo,
  bonusType,
  bonusAmount,
  maxBonus,
  maxBet,
  wager,
  wagerType,
  isNew,
  isDama,
}) => {
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [bonusTooltipOpen, setBonusTooltipOpen] = useState(false);
  const [wagerTooltipOpen, setWagerTooltipOpen] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const handleButtonClick = () => {
    fetch(`${API_URL}/api/click`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ button_id: link, casino_name: title, logo }) // Include logo in the payload
    });
  };
  

  const toggleExtraInfo = () => {
    setShowExtraInfo(!showExtraInfo);
  };

  const handleBonusTooltipOpen = () => {
    if (isMobile) {
      setBonusTooltipOpen(!bonusTooltipOpen);
    }
  };

  const handleWagerTooltipOpen = () => {
    if (isMobile) {
      setWagerTooltipOpen(!wagerTooltipOpen);
    }
  };

  const getWagerTooltipText = (wagerType) => {
    switch (wagerType) {
      case 'B':
        return "Der Wager gibt an, wie häufig ein Bonus eingesetzt werden muss. Dieser Faktor bezieht sich auf den Betrag aus (B) Bonussumme.";
      case 'D':
        return "Der Wager gibt an, wie häufig ein Bonus eingesetzt werden muss. Dieser Faktor bezieht sich auf den Betrag aus (D) Einzahlungssumme.";
      case 'D+B':
        return "Der Wager gibt an, wie häufig ein Bonus eingesetzt werden muss. Dieser Faktor bezieht sich auf den Gesamtbetrag aus (D+B) Einzahlungssumme und Bonussumme.";
      case 'NoWager':
        return "NoWager bedeutet, dass du dein Geld jederzeit auszahlen kannst. Dein Echtgeldguthaben musst du nur einmal einsetzen. Die gesamte Summe wird ausgezahlt, abzüglich der gewährten Bonussumme.";
      default:
        return "Information nicht verfügbar.";
    }
  };

  const getBonusTooltipText = (bonusType) => {
    switch (bonusType) {
      case 'Sticky':
        return "Bei einem Sticky-Bonus wird dein Echtgeld mit dem Bonusguthaben verbunden. Um den Betrag auszahlen zu können, musst du ihn je nach den festgelegten Bonusbedingungen mehrmals umsetzen. Erst wenn diese Umsatzanforderungen vollständig erfüllt sind, ist eine Auszahlung möglich.";
      case 'NonSticky':
        return "Bei einem Non-Sticky Bonus wird zunächst dein eigenes Echtgeld verwendet. Dadurch sind alle Gewinne, die du damit erzielst, sofort und ohne Bonusbedingungen auszahlbar. Das Echtgeld und das Bonusgeld sind eindeutig voneinander getrennt. Wenn du in den Bereich des Bonusguthabens kommst, musst du die Umsatzanforderungen erfüllen.";
      default:
        return "Information nicht verfügbar.";
    }
  };

  return (
    <Container>
      {isNew && <NewTag isDama={false}>NEU</NewTag>}
      {isDama && <NewTag isDama={true}>Dama Gruppe</NewTag>}
      <Row>
        <Brand>
          <a href={link} rel="nofollow" target="_blank">
            <Logo src={logo} alt={title} />
          </a>
        </Brand>
        <CardColGroupContainer>
          {tag && <Tag>{tag}</Tag>}
          <CardColGroup>
            <Stat>
              <SubtitleLarge>{bonusAmount}%</SubtitleLarge>
              <Small>
                {bonusType}
                <ClickAwayListener onClickAway={() => setBonusTooltipOpen(false)}>
                  <div>
                    <CustomTooltip
                      title="Bonusinfo"
                      text={getBonusTooltipText(bonusType)}
                      open={bonusTooltipOpen}
                      onClick={handleBonusTooltipOpen}
                    >
                      <HelpTwoToneIcon
                        onMouseEnter={isMobile ? undefined : () => setBonusTooltipOpen(true)}
                        onMouseLeave={isMobile ? undefined : () => setBonusTooltipOpen(false)}
                      />
                    </CustomTooltip>
                  </div>
                </ClickAwayListener>
              </Small>
            </Stat>
            <Stat>
              <SubtitleLarge>{maxBonus}€</SubtitleLarge>
              <Small>Max Bonus</Small>
            </Stat>
            <Stat>
              <SubtitleLarge>{maxBet}€</SubtitleLarge>
              <Small>Max Bet</Small>
            </Stat>
            <Stat>
              <SubtitleLarge>{wager}x {wagerType !== 'NoWager' && wagerType}</SubtitleLarge>
              <Small>
                {wagerType === 'NoWager' ? 'No Wager' : 'Wager'}
                <ClickAwayListener onClickAway={() => setWagerTooltipOpen(false)}>
                  <div>
                    <CustomTooltip
                      title="Wagerinfo"
                      text={getWagerTooltipText(wagerType)}
                      open={wagerTooltipOpen}
                      onClick={handleWagerTooltipOpen}
                    >
                      <HelpTwoToneIcon
                        onMouseEnter={isMobile ? undefined : () => setWagerTooltipOpen(true)}
                        onMouseLeave={isMobile ? undefined : () => setWagerTooltipOpen(false)}
                      />
                    </CustomTooltip>
                  </div>
                </ClickAwayListener>
              </Small>
            </Stat>
          </CardColGroup>
        </CardColGroupContainer>
        <Actions>
        <TermsLink>Es gelten die aktuellen Casi AGBs</TermsLink>
        <Button href={link} rel="nofollow" target="_blank" onClick={handleButtonClick}>
          Jetzt Spielen
        </Button>
        <MoreInfoButton onClick={toggleExtraInfo}>
          {showExtraInfo ? "Weniger Informationen" : "Mehr Informationen"}
          <img src={showExtraInfo ? closeIcon : plusIcon} alt={showExtraInfo ? "less" : "more"} />
        </MoreInfoButton>
      </Actions>
      </Row>
      <ExtraInfoContainer show={showExtraInfo}>
        <InfoList>
          <p className="subtitle">Casino Highlight</p>
          <ul>
            {extraInfo.highlights.map((highlight, index) => (
              <li key={index}>
                <CheckIcon src={checkIcon} alt="check" />
                {highlight}
              </li>
            ))}
          </ul>
        </InfoList>
        <PaymentTypeIcons>
          <p className="subtitle">Zahlungsmethoden</p>
          <div className="icon-row">
            {extraInfo.paymentMethods.map((method, index) => (
              <BankingOption key={index} title={method.name}>
                <img src={iconMap[method.name]} alt={method.name} />
              </BankingOption>
            ))}
          </div>
        </PaymentTypeIcons>
      </ExtraInfoContainer>
    </Container>
  );
};

export default BonusEntry;
